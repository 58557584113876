import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
  max-width: calc(var(--unit) * 160);
`;

export const httpError = css`
  margin: 0 0 calc(var(--unit) * 10) 0;
`;

export const twoGridFlexbox = css`
  @media ${breakpoints.medium} {
    display: flex;
    justify-content: left;

    > * {
      flex: 1;
    }
  }
`;

export const profileUploadWrapper = css`
  max-width: 192px;
  margin-bottom: calc(var(--unit) * 5);

  @media ${breakpoints.medium} {
    margin-right: 72px;
  }

  @media ${breakpoints.large} {
    margin-right: 144px;
  }
`;

export const googlePlacesFix = css`
  margin-bottom: calc(var(--unit) * 12) !important;
`;

export const basicsFirstField = css`
  grid-column: 1 / 3;
`;

export const subheaderGroup = css`
  margin: calc(var(--unit) * 20) 0 calc(var(--unit) * 20) 0 !important;
`;

export const form = css`
  @media ${breakpoints.large} {
    max-width: calc(var(--unit) * 160);
  }
`;

export const subSubgridForm = css`
  display: grid;
  grid-template-columns: 2fr 1fr;

  & > div:nth-of-type(odd) {
    margin-right: 33px;
  }
`;

export const phoneContainer = css`
  position: relative;
`;
export const phonePlus = css`
  position: absolute;
  bottom: calc(var(--unit) * 15);
  left: 0;
  margin: 0;
  color: hsl(var(--color-text-1));
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
`;

export const phoneInput = css`
  input {
    padding-left: calc(var(--unit) * 2);
  }

  padding-left: calc(var(--unit) * 2);
`;

export const textEditorWrapper = css`
  min-height: calc(var(--unit) * 62);
`;

export const phoneError = css`
  margin-bottom: ${spacing(64)};
`;
